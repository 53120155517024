<template>
  <footer class="footer full main-container text-center flex center">
    <!-- <h3>Experience the difference</h3>
      <h3 class="mrg0">Newledge&copy;2021</h3> -->
    <!-- <span>providing this information confirms that you agree to receive information and offering from newledge</span> -->
    <div class="section-logo flex center mb10">
        <div class="newledge-logo logo">
          <img
            src="@/assets/img/logo.png"
            alt=""
            class="logo-img"
          />
        </div>
        <h3 class="mrg0">Learn. Different.</h3>
      </div>
  </footer>
</template>

<script>
export default {
  name:"app-footer",
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
 
}
</style>
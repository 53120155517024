<template>
  <section class="login flex column center text-center small-container">
    <el-button v-if="loggedinUser" class="button" @click="logout"
      >Logout</el-button
    >
    <section class="flex column center" v-else>
      <section v-if="showLogin" class="login-form">
        <div class="img-form"></div>
        <h3>Login</h3>
        <form @submit.prevent="login">
          <el-input
            v-model="loginCred.fullName"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Username'"
            placeholder="Username"
            clearable
            suffix-icon="el-icon-edit"
          >
            <!-- <i class="el-icon-edit el-input__icon" slot="suffix"> </i> -->
          </el-input>
          <el-input
            v-model="loginCred.password"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Password'"
            show-password
            placeholder="Password"
            clearable
            suffix-icon="fas fa-key"
          ></el-input>
          <!-- <p class="forgot-pass-btn hover-pointer">Forgot Password?</p> -->
          <button type="button" class="button" @click.stop="login">
            Login
          </button>
        </form>
        <div class="flex column center">
          <p class="mb10">Don't have an account yet?</p>
          <a
            class="show-signup-btn hover-pointer"
            @click="() => (this.showLogin = false)"
            >Create an account</a
          >
        </div>
      </section>
      <section v-else class="signup">
        <h3>Sign-up</h3>
        <form @submit.prevent="signup" class="flex column center">
          <el-input
            v-model="signupCred.fullName"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Username'"
            placeholder="Username"
            clearable
          ></el-input>
          <el-input
            v-model="signupCred.password"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Password'"
            show-password
            placeholder="Password"
            clearable
          ></el-input>
          <el-input
            v-model="signupCred.email"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Email'"
            placeholder="Email"
            clearable
          ></el-input>
          <el-input
            v-model="signupCred.company"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Company'"
            placeholder="Company"
            clearable
          ></el-input>

          <!-- <label class="img-list">
            
            <section v-if="signupCred.compImg" class="img flex center">
              <img
                class="border-radius mb10"
                :src="signupCred.compImg"
                alt="img..."
              />
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                class="remove-img"
                @click.stop.prevent="removeImg()"
              ></el-button>
            </section>
          </label> -->

          <section class="upload-img flex column center">
            <p>Company Picture:</p>
            <template v-if="!isLoading">
              <label for="imgUploader">
                <i class="fas fa-cloud-upload-alt hover-pointer"></i>
              </label>
              <input
                type="file"
                name="img-uploader"
                id="imgUploader"
                @change="onUploadImg"
              />
            </template>
            <img
              class="loader"
              v-else
              src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif"
              alt=""
            />
            <div v-if="compImg" class="img-list">
              <section class="imgs flex center">
                <img class="border-radius mrg5" :src="compImg" alt="img..." />
              </section>
            </div>
          </section>
          <button type="button" class="button" @click.stop="signup">
            Sign
          </button>
        </form>
        <div class="flex column center">
          <p class="mb10">Already have an account?</p>
          <a
            class="show-signup-btn hover-pointer"
            @click="() => (this.showLogin = true)"
            >Log in here</a
          >
        </div>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: "old-login",
  data() {
    return {
      loginCred: {},
      signupCred: { compImg: null },
      msg: "",
      isLoading: false,
      compImg: null,
      showLogin: true,
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    async onUploadImg(ev) {
      this.isLoading = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.compImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoading = false;
    },
    removeImg() {
      this.signupCred.compImg = null;
    },

    async login() {
      if (this.loginCred.fullName && this.loginCred.password) {
        console.table("this.loginCred", this.loginCred);
        var res = await this.$store.dispatch({
          type: "login",
          userCred: this.loginCred,
        });
        console.log(res);
        if (!res.err) {
          if (res.endDate && res.endDate < Date.now()) {
            this.$message({
              showClose: true,
              message: `Your account expired.`,
              type: "warning",
              duration: 3000,
            });
            this.$store.dispatch({ type: "logout" });
            this.loginCred = {};
            return;
          }
          if (res.startDate && res.startDate > Date.now()) {
            this.$message({
              showClose: true,
              message: `Your account exists but is not active.`,
              type: "warning",
              duration: 3000,
            });
            this.$store.dispatch({ type: "logout" });
            this.loginCred = {};
            return;
          }

          this.$message({
            showClose: true,
            message: `${JSON.parse(
              JSON.stringify(this.loginCred.fullName)
            )} loginned sucessfully!`,
            type: "success",
            duration: 1500,
          });
          this.loginCred = {};
          this.$router.push("/profile");
        } else {
          this.$message({
            showClose: true,
            message: `Your user/password wrong, please try again.`,
            type: "warning",
            duration: 1500,
          });
          return;
        }
      } else {
        this.$message({
          showClose: true,
          message: `Username/Password must be present`,
          type: "warning",
          duration: 1500,
        });
        console.table("this.loginCred", this.loginCred);
      }
    },
    async signup() {
      this.signupCred.compImg = this.compImg;
      if (
        this.signupCred.fullName &&
        this.signupCred.password &&
        this.signupCred.email
      ) {
        var numImg = Math.floor(Math.random() * 14) + 1;
        this.signupCred.img = `shutterstock_1575446116_${numImg}.png`;
        var res = await this.$store.dispatch({
          type: "signup",
          userCred: JSON.parse(JSON.stringify(this.signupCred)),
        });
        if (!res.err) {
          await this.$message({
            showClose: true,
            message: `${this.signupCred.fullName} signup sucessfully!`,
            type: "success",
            duration: 1500,
          });
          this.signupCred = {};
          this.$router.push("/profile");
        } else {
          this.$message({
            showClose: true,
            message: `Something was wrong, please try again.`,
            type: "warning",
            duration: 1500,
          });
          return;
        }
      } else {
        console.table("this.signupCred", this.signupCred);
        this.$message({
          showClose: true,
          message: `Some input is incorrect`,
          type: "warning",
          duration: 1500,
        });
      }
    },
    logout() {
      this.$store.dispatch({ type: "logout" });
      this.$message({
        showClose: true,
        message: `${this.loggedinUser.fullName} logout sucessfully!`,
        type: "success",
        duration: 1500,
      });
    },
  },
  created() {
    document.body.classList.add("hero-page");
  },
  destroyed() {
    document.body.classList.remove("hero-page");
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
form {
  .el-input.el-input--suffix {
    .el-input__suffix {
      left: 5px !important;
    }
  }
}
.main-color {
  color: $primary_color;
}
.login {
  height: 100%;
}
.login .singup {
  width: 500px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
}
#imgUploader {
  display: none;
}
form {
  margin-bottom: 16px;
  position: relative;
}
.show-signup-btn {
  color: #07eda8;
}
.login-form {
  max-width: 250px;
  .img-form {
    width: 100%;
    height: 175px;
    background-image: url(../assets/img/img-login.jpg);
    background-size: cover;
    margin-bottom: 10px;
    background-repeat: no-repeat;
  }
}
.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: white;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
.forgot-pass-btn {
  position: absolute;
  right: 0;
  font-size: 12px;
  color: #00f5af;
}
/* h2 {
  margin-top: 10px;
  padding: 5px;
  border: solid 2px black;
  background-color: #69bd42;
  width: 300px;
} */
</style>
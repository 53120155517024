/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://a62t7pyo5bfp3jxvmsfyk7syqu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zy2alwio7fccdj26zwmo6skhu4",
    "aws_cognito_identity_pool_id": "us-east-2:59273d3f-619d-4427-9c44-282309c8988c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_1o3utR1BB",
    "aws_user_pools_web_client_id": "355str9li8503g1mhq0qh0ns56",
    "oauth": {}
};


export default awsmobile;
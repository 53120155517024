import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './style/main.scss'
import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
    applyPolyfills,
    defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
    defineCustomElements(window);
});
Vue.use(VueEllipseProgress);
Vue.use(ElementUI, { locale });
moment().format();

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fullName
      AuthId
      company
      totalScore
      img
      downloadSpeed {
        timestamp
        downloadSpeed
      }
      compImg
      secCompImg
      isAdmin
      scores {
        gameId
        type
        score
        time
        done
        startDownload
        endDownload
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        AuthId
        company
        totalScore
        img
        downloadSpeed {
          timestamp
          downloadSpeed
        }
        compImg
        secCompImg
        isAdmin
        scores {
          gameId
          type
          score
          time
          done
          startDownload
          endDownload
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

<template>
  <div>
    <!-- <h3>Download Speed {{ downloadSpeed }}</h3>
    <h3>Upload Speed {{ uploadSpeed }}</h3> -->
    <quiz></quiz>
  </div>
</template>

<script>
import quiz from '../cmp/quiz'
import NetworkSpeed from "network-speed";
export default {
  name: "speedTest",
  components: {
    quiz
  },
  data() {
    return {
      downloadSpeed: null,
      uploadSpeed: null,
    };
  },
  methods: {
    async getNetworkDownloadSpeed() {
      const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
      const fileSizeInBytes = 500000;
      const testNetworkSpeed = new NetworkSpeed();
      const speed = await testNetworkSpeed.checkDownloadSpeed(
        baseUrl,
        fileSizeInBytes
      );
      console.log("speed", speed);
      this.downloadSpeed = speed;
    },
    async getNetworkUploadSpeed() {
      const options = {
        hostname: "www.google.com",
        port: 80,
        path: "/catchers/544b09b4599c1d0200000289",
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const fileSizeInBytes = 2000000;
      const testNetworkSpeed = new NetworkSpeed();
      const speed = await testNetworkSpeed.checkUploadSpeed(
        options,
        fileSizeInBytes
      );
      console.log(speed);
      this.uploadSpeed = speed;
    },
  },
  async created() {
    this.getNetworkDownloadSpeed();
    // this.getNetworkUploadSpeed();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <div class="login-container flex center" v-show="styleReady">
    <div class="layout-img" v-if="showImg">
      <div class="img-form"></div>
    </div>
    <amplify-authenticator>
      <div class="loading flex center">
        <img
          class="loading-svg text-center"
          src="@/assets/loading.svg"
          @click="logout"
          alt=""
          srcset=""
        />
      </div>
    </amplify-authenticator>
    <!-- <amplify-sign-in></amplify-sign-in>
  <amplify-sign-up></amplify-sign-up> -->
  </div>
</template>

<script>
// import { AmplifyEventBus } from "aws-amplify-vue";
import { Hub } from "aws-amplify";

export default {
  name: "login",
  data() {
    return {
      styleReady: false,
      showImg: true,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch({
        type: "logout",
      });
    },
  },
  async created() {
    await this.$store.dispatch({
      type: "logout",
    });
  },
  mounted() {
    setTimeout(() => {
      var host = document.querySelector("amplify-authenticator");
      document.addEventListener("click", (ev) => {
        if (ev.path[0].className != "anchor") return;
        this.styleReady = false;
        setTimeout(() => {
          var host = document.querySelector("amplify-authenticator");
          if (
            host.shadowRoot.querySelector(".auth-container amplify-sign-up")
          ) {
            var signUp = host.shadowRoot.querySelector(
              ".auth-container amplify-sign-up"
            );
            var styleSignIn = document.createElement("style");
            styleSignIn.innerHTML = ".section { text-align: center; }";
            signUp.shadowRoot.appendChild(styleSignIn);
          } else if (
            host.shadowRoot.querySelector(".auth-container amplify-sign-in")
          ) {
            signIn = host.shadowRoot.querySelector(
              ".auth-container amplify-sign-in"
            );
            styleSignIn = document.createElement("style");
            styleSignIn.innerHTML = ".section { text-align: center; }";
            signIn.shadowRoot.appendChild(styleSignIn);
          } else if (
            host.shadowRoot.querySelector(
              ".auth-container amplify-forgot-password"
            )
          ) {
            var forgotPass = host.shadowRoot.querySelector(
              ".auth-container amplify-forgot-password"
            );
            var styleForgotPass = document.createElement("style");
            styleForgotPass.innerHTML = ".section { text-align: center; }";
            forgotPass.shadowRoot.appendChild(styleForgotPass);
          }
          console.log(host.shadowRoot.querySelector(".auth-container").style);
          host.shadowRoot.querySelector(".auth-container").style.minHeight =
            "100%";
          this.styleReady = true;
        }, 500);
      });
      console.log(host.shadowRoot.querySelector(".auth-container"));
      if(host.shadowRoot.querySelector(".auth-container")) {
        host.shadowRoot.querySelector(".auth-container").style.minHeight = "100%";
      }
      var signIn = host.shadowRoot.querySelector(
        ".auth-container amplify-sign-in"
      );
      var input = host.shadowRoot
        .querySelector(".auth-container amplify-sign-in")
        .shadowRoot.querySelector("amplify-input");
      console.log(input);
      var inputUsername = host.shadowRoot
        .querySelector(".auth-container amplify-sign-in")
        .shadowRoot.querySelector("input[id='username']");
      console.log(inputUsername);
      var inputPassword = host.shadowRoot
        .querySelector(".auth-container amplify-sign-in")
        .shadowRoot.querySelector("input[id='password']");
      console.log(inputPassword);
      var styleSignIn = document.createElement("style");
      styleSignIn.innerHTML = ".section { text-align: center; }";
      signIn.shadowRoot.appendChild(styleSignIn);
      this.styleReady = true;
    }, 500);

    Hub.listen("auth", async (eventInfo) => {
      console.log("auth event:", eventInfo);
      this.showImg = false;
      if (eventInfo.payload.event === "signIn") {
        var res = await this.$store.dispatch({
          type: "newLogin",
          userCred: eventInfo.payload.data,
        });
        console.log(res);
        if (res.err) {
          this.$message({
            showClose: true,
            message: `${res.err}`,
            type: "warning",
            duration: 3000,
          });
          this.$router.push("/");
          this.logout()
        } else {
          this.$router.push("/profile");
        }
      } else if (eventInfo.payload.event === "signOut") {
        this.$router.push("/");
        this.showImg = true;
        this.styleReady = true;
      } else if (eventInfo.payload.event === "signUp") {
        var newUser = await this.$store.dispatch({
          type: "signup",
          userCred: eventInfo.payload.data,
        });
        console.log("after signup", newUser);
      } else if (
        eventInfo.payload.event === "signUp_failure" ||
        eventInfo.payload.event === "signIn_failure"
      ) {
        this.showImg = true;
        this.$message({
          showClose: true,
          message: `${eventInfo.payload.data.message}`,
          type: "warning",
          duration: 5000,
        });
        this.logout()
      }
    });
  },
};
</script>

<style lang="scss">
@import '../style/setup/mixin';
@import '../style/setup/var';
.loading {
  width: 100%;
  .loading-svg {
    width: 350px;
  }
}
.layout-img {
  width: 28.75rem;
  height: 250px;
  .img-form {
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/img-login.jpg);
    background-size: cover;
    margin-bottom: 10px;
    background-repeat: no-repeat;
  }
}
:root {
  --amplify-primary-color: #07eda8;
  --amplify-primary-tint: #07eda88f;
  --amplify-primary-shade: #07eda8;
  --amplify-background-color: black;
  --amplify-secondary-color: white;
  --amplify-secondary-contrast: black;
}

.login-container {
  flex-direction: column;
  @include for-wide-layout {
    flex-direction: row;
    height: 100%;
  }
}
</style>
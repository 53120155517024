import { userService } from '../../service/user-service.js'
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { createUser } from "../../graphql/mutations";
import { updateUser } from "../../graphql/mutations";
import { listUsers } from "../../graphql/queries";
import { getUser } from "../../graphql/queries";

var localLoggedinUser = null;
var localLoggedinUserAuth = null;
if (sessionStorage.user) localLoggedinUser = JSON.parse(sessionStorage.user);
if (sessionStorage.userAuth) localLoggedinUserAuth = JSON.parse(sessionStorage.userAuth);

export const userStore = {
    state: {
        loggedinUser: localLoggedinUser,
        loggedinUserAuth: localLoggedinUserAuth,
        users: []
    },
    getters: {
        users(state) {
            return state.users;
        },
        loggedinUser(state) {
            return state.loggedinUser
        },
        loggedinUserAuth(state) {
            return state.loggedinUserAuth
        }
    },
    mutations: {
        setUserAuth(state, { user }) {
            state.loggedinUserAuth = user;
            sessionStorage.userAuth = JSON.stringify(user)
        },
        setUser(state, { user }) {
            state.loggedinUser = user;
            sessionStorage.user = JSON.stringify(user)
        },
        setUsers(state, { users }) {
            state.users = users;
        },
        removeUser(state, { userId }) {
            state.users = state.users.filter(user => user._id !== userId)
        },
    },
    actions: {
        async newLogin(context, { userCred }) {
            try {
                console.log(userCred);
                console.log(userCred.username);
                console.log(userCred.signInUserSession.accessToken.payload.sub);
                // var res = await API.graphql({
                //     query: listUsers,
                //     "variables": { filter: { AuthId: { contains: userCred.signInUserSession.accessToken.payload.sub } } }

                // });
                // console.log(res);
                // var findUser = res.data.listUsers.items[0]
                // console.log(findUser);
                const user = await userService.login(userCred.signInUserSession.accessToken.payload.sub);
                console.log(user);
                var findUser = user;
                // console.log(user.data.listUsers.items);
                // var findUser = user.data.listUsers.items.find(user => user.AuthId === userCred.signInUserSession.accessToken.payload.sub)
                console.log('find user', findUser);
                context.commit({ type: 'setUserAuth', user: userCred })
                context.commit({ type: 'setUser', user: findUser })
                return findUser
            } catch (err) {
                console.log(err);
                return { err: err.errors[0].message }
            }
        },
        async login(context, { userCred }) {
            try {
                const user = await userService.login(userCred);
                console.log(user);
                context.commit({ type: 'setUser', user })
                return user;
            } catch (err) {
                return { err }
            }
        },
        async signup(context, { userCred }) {
            try {
                // const userAuth = await userService.signup(userCred)
                const userAuth = JSON.parse(JSON.stringify(userCred))
                const credentials = await Auth.currentUserCredentials();
                console.log("identityId", credentials.identityId);
                var user = {}
                console.log(userAuth);
                user.fullName = userAuth.user.username
                console.log(userAuth.user.pool.clientId);
                console.log(userAuth.userSub);
                user.AuthId = userAuth.userSub
                var res = await API.graphql({
                    query: createUser,
                    variables: { input: user },
                });
                console.log(res);
                if (!res.err) {
                    var newUser = res.data.createUser
                    console.log(newUser);
                    context.commit({ type: 'setUser', user: newUser })
                    console.log("signup -> user", newUser)
                    return newUser;
                } else {
                    return { err: res.err }
                }
            } catch (err) {
                return { err }
            }
        },
        async addUser(context, { userCred }) {
            try {
                const user = await userService.add(userCred)
                console.log("signup -> user", user)
                return user;
            } catch (err) {
                return { err }
            }
        },
        async updatePassword(context, { userCred }) {
            try {
                const user = await userService.updatePassword(userCred)
                return user;
            } catch (err) {
                return { err }
            }
        },
        async logout(context) {
            await userService.logout()
            context.commit({ type: 'setUsers', users: [] })
            context.commit({ type: 'setUser', user: null })
            return { type: true, err: null }
        },
        async loadUsers(context) {
            const res = await API.graphql({
                query: listUsers,
            });
            const users = res.data.listUsers.items;
            // const users = await userService.getUsers();
            context.commit({ type: 'setUsers', users })
        },
        async removeUser(context, { userId }) {
            await userService.remove(userId);
            // var res = await API.graphql({
            //     query: deleteUser,
            //     variables: { input: userId },
            // });
            context.commit({ type: 'removeUser', userId })
        },
        async updateUser(context, { user }) {
            delete user.createdAt;
            delete user.updatedAt;
            // user = await userService.update(user);
            var res = await API.graphql({
                query: updateUser,
                variables: { input: user },
            });
            var newUser = res.data.updateUser
            var userAuth = await Auth.currentUserPoolUser()
            var attrAuth = await Auth.userAttributes(userAuth)
            console.log(newUser);
            console.log(userAuth);
            console.log(attrAuth);
            // var resAuth = await Auth.updateUserAttributes(await Auth.currentUserPoolUser(), { preferred_username: newUser.fullName })
            // console.log(resAuth);
            // console.log('update', newUser);
            // console.log(JSON.parse(sessionStorage.user).id === newUser.id);
            if (JSON.parse(sessionStorage.user).id === newUser.id) context.commit({ type: 'setUser', user: newUser })
            return { type: true, newUser }
        },
        async getUserById(context, { _id }) {
            console.log(_id);
            // return await userService.getById(_id);
            var res = await API.graphql({
                query: getUser,
                "variables": { "id": _id }
            });
            var user = res.data.getUser
            console.log(user);
            return user
                // var findUser = user.data.listUsers.items.find(user => user.id === _id)
                // console.log(findUser);
                // return findUser
        }
    }
}
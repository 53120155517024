<template>
  <div class="controll-users flex column center">
    <h2 class="text-center">Controll Users</h2>
    <div v-if="isLoading" class="loading flex center">
      <img class="loading-svg text-center" src="@/assets/loading.svg" />
    </div>
    <div v-else class="flex column center">
      <form class="filter flex center">
        <el-input
          v-model="term"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Search user'"
          placeholder="Search user"
          clearable
          suffix-icon="fas fa-search"
          class="text-center"
        ></el-input>
      </form>
      <div class="users flex center wrap">
        <section
          class="user flex column center"
          v-for="user in users"
          :key="user.id"
        >
          <img
            @click="() => $router.push(`/userDetails/${user.id}`)"
            class="user-img hover-pointer"
            :src="require(`@/assets/img/avatars/${user.img}`)"
          />
          <h3>
            Name: <br />
            {{ user.fullName }}
          </h3>
          <h3>
            Company: <br />
            {{ user.company }}
          </h3>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "controllUsers",
  data() {
    return {
      term: "",
      isLoading: true,
      users: []
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    getUsers() {
      this.isLoading = true;
      var users = this.$store.getters.users;
      if (this.term === "") {
        this.isLoading = false;
        console.log(this.isLoading);
        this.users = users;
      }
      const usersToDisplay = users.filter((user) => {
        return user.fullName.toUpperCase().includes(this.term.toUpperCase());
      });
      this.isLoading = false;
      console.log(this.isLoading);
      this.users = usersToDisplay;
    },
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
      return;
    }
    if (!this.loggedinUser.isAdmin) {
      this.$message({
        showClose: true,
        message: `Not allowed!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/profile");
      return;
    }
    await this.$store.dispatch({ type: "loadUsers" });
    this.getUsers()
  },
};
</script>

<style lang="scss" scoped>
.controll-users {
  & .filter {
    max-width: 150px;
  }
  & .users {
    & .user {
      padding: 10px;
      margin: 10px;
      max-width: 200px;
      text-align: center;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      .user-img {
        border-radius: 50%;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
<template>
  <main class="profile flex column center">
    <!-- <h2>Home Page</h2> -->
    <div
      class="modal"
      :style="{
        position: 'absolute',
        top: `${modaltop}`,
        left: `${modalleft}`,
      }"
      v-show="showModal"
      ref="modal"
    >
      Just For Checking...
    </div>
    <div v-if="loggedinUser" class="details-user flex column center">
      <div
        v-if="loggedinUser.img"
        class="avatar-img avatar mr10 mb10"
        :style="{
          backgroundImage:
            'url(' + require(`@/assets/img/avatars/${loggedinUser.img}`) + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }"
      >
        <i
          @click="showAvatars = !showAvatars"
          class="far fa-edit change-icon hover-pointer"
        ></i>
      </div>
      <div v-if="showAvatars" class="avatars flex center wrap">
        <div
          v-for="index in 14"
          :key="index"
          @click="(ev) => changeAvatar(ev)"
          class="avatar-img mr10 mb10 hover-pointer"
          :style="{
            backgroundImage:
              'url(' +
              require(`@/assets/img/avatars/shutterstock_1575446116_${index}.png`) +
              ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }"
        />
      </div>
      <h3 class="mr16">Hi {{ loggedinUser.fullName.split("_")[0] }}</h3>
    </div>
    <h3 v-else>Hi guest, please login first</h3>
    <div class="sub-title text-center">
      <p>Are you ready for the challenge?</p>
      <p>Complete the 3D games to get the highest score and win cool prizes!</p>
    </div>
    <!-- <h3>Please choose your experience</h3> -->
    <h3 v-if="!avg">Check Download Speed...</h3>
    <h3 v-if="avg && avg < 40">
      Your connection speed is lower then recommanded
    </h3>

    <div class="cells flex center wrap text-center">
      <div class="scenario flex column center mrg5">
        <div @click="toPage('/scenario')" class="cell cell-scenario"></div>
        <div class="cell-text flex column center" @click="toPage('/scenario')">
          <p>Protect your data</p>
          <p>play to keep {{ loggedinUser.company }} safe</p>
        </div>
      </div>
      <div class="cyber-room flex column center mrg5">
        <div @click="toPage('/cyber-room')" class="cell cell-cyber-room"></div>
        <div
          class="cell-text flex column center"
          @click="toPage('/cyber-room')"
        >
          <p>It's the little things you do</p>
          <p>enter the Virtual Escape Room</p>
          <p>and solve the challenges</p>
        </div>
      </div>
      <div class="sql-injection flex column center mrg5">
        <div
          @click="toPage('/sql-injection')"
          class="cell cell-sql-injection"
        ></div>
        <div
          class="cell-text flex column center"
          @click="toPage('/sql-injection')"
        >
          <p>Work in a secure mode and</p>
          <p>protect your code</p>
          <p>(Application Security for R&D)</p>
        </div>
      </div>
    </div>
    <div class="hero-text text-center">
      <h2>
        You don't need to be a superhero, to keep
        {{ loggedinUser.company }} safe
      </h2>
    </div>
  </main>
</template>
 
<script>
import NetworkSpeed from "network-speed";
import Axios from "axios";
var axios = Axios.create({
  withCredentials: true,
});
export default {
  name: "profile",
  data() {
    return {
      signupCred: {},
      showAvatars: false,
      downloadSpeed: null,
      avg: null,
      baseUrl:
        "https://nir-webgl.s3.eu-central-1.amazonaws.com/CS_ENG/videos/1080/",
      assets: [
        "Background_Coffee_Machine.mp4",
        "Background_London_4K.mp4",
        "Background_Office.mp4",
        "Background_Sci - fi_Center.mp4",
        "Background_Sci - fi_Center3.mp4",
        "Background_Sci - fi.mp4",
        "Laptop_960.mp4",
        "Opening_Background.mp4",
        "Quiz_Background.mp4",
        "Tunnel_Background.mp4",
      ],
      showModal: false,
      modaltop: 0,
      modalleft: 0,
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    async getNetworkDownloadSpeed() {
      var speeds = [];
      for (let i = 0; i < 30; i++) {
        // const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
        const baseUrl =
          "https://graylinegroup.com/wp-content/uploads/2018/08/Give.jpg";
        //  const baseUrl = "https://cybertraining.amdocs.com/image/unity-logo-light.png";
        const fileSizeInBytes = 22000;
        // const fileSizeInBytes = 500000;
        const testNetworkSpeed = new NetworkSpeed();
        const speed = await testNetworkSpeed.checkDownloadSpeed(
          baseUrl,
          fileSizeInBytes
        );
        speeds.push(speed.mbps);
      }
      var sum = 0;
      speeds.forEach((speed) => {
        sum += +speed;
      });
      var avg = sum / speeds.length;
      this.avg = +avg.toFixed(2);
    },
    toPage(path) {
      this.$router.push(path);
    },
    async changeAvatar(ev) {
      this.showAvatars = false;
      console.log(ev.target.style.backgroundImage.split("_")[2].split(".")[0]);
      var numImg = ev.target.style.backgroundImage.split("_")[2].split(".")[0];
      var img = `shutterstock_1575446116_${numImg}.png`;
      var user = JSON.parse(JSON.stringify(this.loggedinUser));
      user.img = img;
      var res = await this.$store.dispatch({
        type: "updateUser",
        user,
      });
      console.log(res);
    },
    async downloadCache(endpoint) {
      axios
        .get(`${this.baseUrl}${endpoint}`, {
          headers: {
            mode: "no-cors",
          },
        })
        .then(function (response) {
          console.log("response is : " + response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error.message);
          }
          console.log(error.config);
        });
    },
    addClickEvent(e) {
      setTimeout(() => {
        this.$refs["modal"].innerText =
          e.target.parentElement.className.split(" ")[0];
        if (e.type === "mousemove") {
          this.modalleft = e.clientX + 10 + "px";
          this.modaltop = e.clientY - 100 + "px";
        } else if (e.type === "mouseenter") {
          this.showModal = true;
        } else if (e.type === "mouseleave") {
          this.showModal = false;
        }
      }, 10);
    },
  },
  mounted() {
    this.$parent.changeLogo();
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
      return;
    }
    await this.getNetworkDownloadSpeed();
    var user = JSON.parse(JSON.stringify(this.loggedinUser));
    if (!user.downloadSpeed) user.downloadSpeed = [];
    var speedTest = {};
    speedTest.timestamp = JSON.stringify(Date.now());
    speedTest.downloadSpeed = JSON.parse(JSON.stringify(this.avg));
    user.downloadSpeed.push(speedTest);
    if (!user.img) {
      var numImg = Math.floor(Math.random() * 14) + 1;
      user.img = `shutterstock_1575446116_${numImg}.png`;
    }
    await this.$store.dispatch({ type: "updateUser", user });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 9px 18px;
  background-color: #00f5b0;
  color: white;
  border: #00f5b0 1px solid;
  width: 200px;
  height: 100px;
  z-index: 9;
}
.profile {
  margin-top: 30px;
}
.hero-text {
  // background: linear-gradient(to right, #fda204, #f60473);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  color: #07eda8;
  margin-top: 60px;
}
.change-icon {
  color: black;
  width: 25px;
  margin: 5px 7px;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}
.avatar {
  &:hover + img {
    opacity: 1;
  }
}
.avatars {
  margin-top: 10px;
}
.avatar-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 2pt solid #07eda8;
  // border-radius: 12pt;
  box-shadow: 0 0 5px #07eda8;
  &.big {
    width: 150px;
    height: 150px;
  }
}
.cells {
  color: #00f5af;
  font-size: 16pt;
  text-transform: capitalize;
  h4 {
    font-size: 18px;
  }
  // .sql-injection {
  // position: relative;
  // p {
  //   position: absolute;
  //   font-size: 11.5px;
  //   bottom: -10px;
  // }
  // }
  & .cell-text {
    height: 80px;
    text-transform: initial;
    & > * {
      font-size: 15px;
      margin-bottom: 5px;
    }
  }
  .cell {
    border: 2pt solid #00f5af;
    // border-radius: 12pt;
    box-shadow: 0 0 5px #00f5af;
    width: 220px;
    height: 120px;
    margin: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.cell-scenario {
      background-image: url(../assets/img/scenario.png);
    }
    &.cell-cyber-room {
      background-image: url(../assets/img/escape-room.png);
    }
    &.cell-sql-injection {
      background-image: url(../assets/img/sql-injection.png);
    }
    &:hover {
      box-shadow: 0 0 8px #00f5af;
    }
  }
  & > *:hover {
    text-shadow: 0 0 8px #00f5af;
    cursor: pointer;
  }
}
</style>
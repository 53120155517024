<template>
  <div v-if="user" class="newUpdateUser">
    <h3>User Update</h3>
    <form @submit.prevent="addUser" class="flex column center">
      <el-input
        v-model="user.fullName"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Username'"
        placeholder="Username"
        clearable
        suffix-icon="el-icon-edit"
      ></el-input>
      <el-input
        v-model="user.company"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Company'"
        placeholder="Company"
        clearable
        suffix-icon="far fa-building"
      ></el-input>
      <button type="button" class="button" @click.stop="addUser">
        Update User
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "newUserUpdate",
  data() {
    return {
      user: null,
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    async addUser() {
      var user = JSON.parse(JSON.stringify(this.user));
      delete user.createdAt;
      delete user.updatedAt;
      console.log(user);
      var res = await this.$store.dispatch({
        type: "updateUser",
        user,
      });
      console.log(res);
      if (res.newUser) {
        this.$message({
          showClose: true,
          message: `${res.newUser.fullName} update sucessfully!`,
          type: "success",
          duration: 1500,
        });
        this.$router.push('/profile')
      } else {
        this.$message({
          showClose: true,
          message: `Something was wrong, please try again.`,
          type: "warning",
          duration: 1500,
        });
      }
    },
  },
  async created() {
    const _id = this.$route.params.userId;
    console.log(_id);
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id,
    });
    this.user = JSON.parse(JSON.stringify(user));
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: white;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
</style>
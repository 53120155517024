<template>
  <!-- <div class="circles flex center wrap"> -->
  <vue-ellipse-progress
    :progress="tasksDonePercent"
    :legend-value="value"
    v-bind="options"
    :emptyColorFill="colorFillGradient"
    :emptyColor="emptyColor"
  >
      <span v-if="type != 'Users'" slot="legend-value">%</span>
    <div class="caption flex column center" slot="legend-caption">
      <i :class="icon"></i> {{ type }}
    </div>
  </vue-ellipse-progress>
  <!-- <vue-ellipse-progress :progress="companys" v-bind="options">
      <div class="caption flex column center" slot="legend-caption"> <i class="fa fa-user-secret" aria-hidden="true"></i> COMPANYS </div>
    </vue-ellipse-progress>
  </div> -->
</template>

<script>
export default {
  name: "Example1",
  props: ["amount", "type", "icon", "max"],
  data() {
    return {
      value: this.type != "Users" ? this.tasksDonePercent : this.amount,
      colorFillGradient:  this.amount < 70 ? "#f5001080" : "transparent",
      emptyColor: this.amount < 70 ? "#153CFC" : "#153CFC",
      options: {
        color: "#00f5af",
        size: 180,
        thickness: 5,
        "empty-thickness": 3,
        "line-mode": "out 5",
        animation: "rs 700 1000",
        "font-size": "1.5rem",
        "font-color": "#00f5af",
      },
    };
  },
  computed: {
    tasksDonePercent() {
      return (this.amount * 50) / (this.max / 2);
    },
  },
  mounted() {
    if (this.type === "Virtual Cyber Room") {
      document.getElementsByClassName("caption")[1].classList.add("mb-20");
    }
  },
};
</script>

<style lang="scss" scoped>
.mb-20 {
  margin-bottom: -20px;
}
i {
  font-size: 30px;
  margin-bottom: 6px;
  color: #00f5af;
}
.caption {
  color: #00f5af;
  width: 120px;
}
</style>
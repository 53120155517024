<template>
  <div class="scenario flex column center">
    <!-- <game v-if="!readyIframe"></game> -->
    <quiz :quizNew="quizScenario" v-if="!readyIframe"></quiz>
    <!-- <h3 v-if="!readyIframe" class="hint">Cyber Awareness Words</h3>
    <hangman v-if="!readyIframe"></hangman> -->

    <!-- <button
      class="btn-show-iframe button"
      v-if="done && !readyIframe"
      @click="showIframe"
    >
      Go To Scenario
    </button> -->
    <iframe
      v-show="readyIframe"
      ref="iframe3"
      @load="sendId"
      src="https://staging.new-ledge.com/scenarios/cyber-security/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button
      class="button to-profile-btn"
      @click="() => this.$router.push('/profile')"
    >
      Go back to Profile
    </button>
  </div>
</template>

<script>
import quiz from "@/cmp/quiz";
import { utilService } from "@/service/utils/util.service.js";

export default {
  components: {
    quiz,
  },
  name: "scenario",
  data() {
    return {
      id: null,
      done: null,
      readyIframe: null,
      quizScenario: {
        questions: [
          {
            text: "I need to create an excel, containing sensitive data. Should I protect the document? ",
            responses: [
              { text: "No" },
              {
                text: "Yes",
                correct: true,
              },
            ],
            message:
              "You should choose the required level of protection to provide the document (use the document protection feature by clicking file > info > protect document). Also, store it in a secured location and share it on a need to know basis.",
          },
          {
            text: "I need to share a project related document with a customer. Should I do so in a secured manner?",
            responses: [
              { text: "No" },
              {
                text: "Yes",
                correct: true,
              },
            ],
            message:
              "You need to approach your account management and ensure that data is delivered in a secured manner and is compliant with the customer’s data protection regulations.",
          },
        ],
      },
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    showIframe() {
      this.readyIframe = true;
      // this.$refs["iframe3"].contentDocument.getElementById(
      //   "muted"
      // ).checked = false;
      // this.$refs["iframe3"].contentWindow.speaker[0].muted = false;
      // this.$refs["iframe3"].contentWindow.speaker[1].muted = false;
    },
    sendId() {
      this.$refs["iframe3"].contentWindow.postMessage(
        this.loggedinUser.id,
        "*"
      );
      setTimeout(() => {
        this.done = true;
      }, 15000);
    },
  },
  async destroyed() {
    console.log("scenario destoyed");
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id: this.loggedinUser.id,
    });
    var idx = user.scores.findIndex((game) => {
      console.log(game.gameId, this.id);
      return game.gameId === this.id;
    });
    if (idx != -1) {
      if (user.scores[idx].endDownload) user.scores[idx].done = true;
      else {
        user.scores[idx].endDownload = Date.now() + "";
        user.scores[idx].done = false;
        user.scores[idx].time = (
          (user.scores[idx].endDownload - user.scores[idx].startDownload) /
          100
        ).toFixed(0);
      }
      await this.$store.dispatch({ type: "updateUser", user });
    }
  },
  mounted() {
    this.$parent.changeLogo();
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
      return;
    }
    var user = JSON.parse(JSON.stringify(this.loggedinUser));
    if (!user.scores) user.scores = [];
    var game = {};
    game.gameId = utilService.makeId(10);
    this.id = game.gameId;
    game.type = this.$route.path.substring(1);
    game.startDownload = Date.now() + "";
    user.scores.push(game);
    await this.$store.dispatch({ type: "updateUser", user });
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.scenario {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 10px;
  //   left: 15px;
  //   padding: 10px;
  // }
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>
import router from '@/router'


// const BASE_URL = process.env.NODE_ENV === 'production' ?
//     '/api/' :
//     '//localhost:3000/api/'
const BASE_URL = 'https://a5kjpjky01.execute-api.us-east-2.amazonaws.com/prod/'


import Axios from 'axios';
var axios = Axios.create({
    withCredentials: true
});

export default {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    }
}


async function ajax(endpoint, method = 'get', data = null) {
    console.log(`${BASE_URL}${endpoint}`);
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            // headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Access-Control-Allow-Credentials": "true",
            //     "Access-Control-Expose-Headers": "Date, x-api-id",
            //     "Access-Control-Max-Age": 300,
            //     "Access-Control-Allow-Methods": "*",
            //     "Access-Control-Allow-Headers": "*"
            // }
        })
        console.log("ajax -> res.data", res.data)
        return res.data;
    } catch (err) {
        if (err.response.status === 401) {
            router.push('/');
        }
        console.log(`Had issues ${method}ing to server`, err)
        throw err;
    }
}
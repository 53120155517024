
<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-vertical-demo"
    mode="vertical"
    :router="true"
  >
    <!--  -->
    <el-submenu index="1" class="hamburger">
      <template slot="title" class="hamburger"><i ref="hamburger" class="fas fa-bars"></i></template>
          <el-submenu index="2" class="user-menu">
      <template slot="title" class="user-menu">
        <img v-if="loggedinUser && loggedinUser.img" :src="require(`@/assets/img/avatars/${loggedinUser.img}`)" alt="">
        <span v-else><i style="fontSize: 25px;" class="fas fa-user-circle"></i></span>
      </template>
      <el-menu-item :index="`/userDetails/${userId}`" :disabled="!loggedinUser"
        >User profile</el-menu-item
      >
      <el-menu-item v-if="loggedinUser && loggedinUser.isAdmin" index="/controllUsers">Controll Users</el-menu-item>
      <!-- <el-menu-item v-if="loggedinUser && loggedinUser.isAdmin" @click="clickHamburger" index="/addUser">Add User</el-menu-item> -->
      <el-menu-item v-if="loggedinUser" @click="logout" index="/">Logout</el-menu-item>
      <el-menu-item v-if="!loggedinUser" @click="clickHamburger" index="/">Login</el-menu-item>
      <!-- <el-menu-item v-if="!loggedinUser" index="/login">SignUp</el-menu-item> -->
    </el-submenu>

    <el-menu-item :disabled="!loggedinUser" @click="clickHamburger" index="/profile">Profile</el-menu-item>
    <el-menu-item :disabled="!loggedinUser" @click="clickHamburger" index="/leaderboard">Leaderboard</el-menu-item>
    <el-menu-item :disabled="!loggedinUser" @click="clickHamburger" index="/dashboard">Dashboard</el-menu-item>

      <!-- <el-menu-item v-if="loggedinUser && loggedinUser.org" index="/eventi-edit">Publish new Event</el-menu-item>
      <el-menu-item v-else index="/org-edit">I need volunteers</el-menu-item> -->
    </el-submenu>
    <!--  -->
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    userId() {
      return this.loggedinUser ? this.loggedinUser.id : "0";
    },
  },
  methods: {
    clickHamburger() {
      this.$refs.hamburger.click()
    },
    logout() {
      this.clickHamburger()
      this.$store.dispatch({ type: "logout" });
      this.$message({
        showClose: true,
        message: `${this.loggedinUser.fullName} logout sucessfully!`,
        type: "success",
        duration: 1500,
      });
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // }
  },
};
</script>

<style lang="scss" scoped>
.hamburger {
  position: absolute;
    right: 0;
}
</style>
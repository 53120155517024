/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      fullName
      AuthId
      company
      totalScore
      img
      downloadSpeed {
        timestamp
        downloadSpeed
      }
      compImg
      secCompImg
      isAdmin
      scores {
        gameId
        type
        score
        time
        done
        startDownload
        endDownload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      fullName
      AuthId
      company
      totalScore
      img
      downloadSpeed {
        timestamp
        downloadSpeed
      }
      compImg
      secCompImg
      isAdmin
      scores {
        gameId
        type
        score
        time
        done
        startDownload
        endDownload
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      fullName
      AuthId
      company
      totalScore
      img
      downloadSpeed {
        timestamp
        downloadSpeed
      }
      compImg
      secCompImg
      isAdmin
      scores {
        gameId
        type
        score
        time
        done
        startDownload
        endDownload
      }
      createdAt
      updatedAt
    }
  }
`;

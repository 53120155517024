
<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    :router="true"
  >
    <el-menu-item :disabled="!loggedinUser" index="/profile">Profile</el-menu-item>
    <el-menu-item :disabled="!loggedinUser" index="/leaderboard">Leaderboard</el-menu-item>
    <el-menu-item :disabled="!loggedinUser" index="/dashboard">Dashboard</el-menu-item>

    <el-submenu index="2" class="user-menu">
      <template slot="title" class="user-menu">
        <img v-if="loggedinUser && loggedinUser.img" :src="require(`@/assets/img/avatars/${loggedinUser.img}`)" alt="">
        <span v-else ><i style="fontSize: 25px;" class="fas fa-user-circle"></i></span>
      </template>
      <el-menu-item :index="`/userDetails/${userId}`" :disabled="!loggedinUser"
        >User Profile</el-menu-item
      >
      <el-menu-item v-if="loggedinUser && loggedinUser.isAdmin" index="/controllUsers">Controll Users</el-menu-item>
      <!-- <el-menu-item v-if="loggedinUser && loggedinUser.isAdmin" index="/addUser">Add User</el-menu-item> -->
      <el-menu-item v-if="loggedinUser" @click="logout" index="/">Logout</el-menu-item>
      <el-menu-item v-if="!loggedinUser" index="/">Login</el-menu-item>
      <!-- <el-menu-item v-if="!loggedinUser" index="/">SignUp</el-menu-item> -->
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    userId() {
      return this.loggedinUser ? this.loggedinUser.id : "0";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch({ type: "logout" });
      this.$message({
        showClose: true,
        message: `${this.loggedinUser.fullName} logout sucessfully!`,
        type: "success",
        duration: 1500,
      });
      this.$router.push('/')
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // }
  },
};
</script>
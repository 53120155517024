<template>
  <div id="app" class="main-container">
    <app-header></app-header>
    <main ref="appList" class="mb10">
      <router-view />
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import "@/style/main.scss";
import appHeader from "@/cmp/app-header";
import appFooter from "@/cmp/app-footer";

export default {
  name: "PortalApp",
  components: {
    appHeader,
    appFooter,
  },
  methods: {
    changeLogo() {
      var someImg = document.querySelector(".company-logo img");
      if (someImg.naturalWidth > someImg.naturalHeight) {
        // console.log("landscape");
        return
      } else if (someImg.naturalWidth < someImg.naturalHeight) {
        // console.log("portrait");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
      } else {
        // console.log("squre");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
      }
    },
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: regular;
  src: url(./assets/fonts/MarkPro/MarkPro-ExtraLight.ttf);
}
// @font-face {
//   font-family: regular;
//   src: url(./assets/fonts/VarelaRound/VarelaRound-Regular.ttf);
// }

// @font-face {
//   font-family: regular;
//   src: url(./assets/fonts/Hind/Hind-Light.ttf);
// }

@font-face {
  font-family: hindBold;
  src: url(./assets/fonts/Hind/Hind-Bold.ttf);
}
</style>

<template>
  <section class="dashboard flex column center">
    <h2 class="text-center">Dashboard</h2>
    <p class="demo-text">This environment is for Demo display only</p>
    <div class="options-top flex space-around wrap">
      <div class="left flex center">
        <el-select class="mb10" v-model="value" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="icon flex column center">
          <i
            @mouseenter="() => (this.showExportData = true)"
            @mouseleave="() => (this.showExportData = false)"
            class="hover-pointer fas fa-file-download"
          ></i>
          <p v-if="showExportData">Export Data</p>
        </div>
      </div>
      <div class="radio flex center">
        <el-radio-group fill="#00f5af" text-color="#ffffff" v-model="radio">
          <el-radio label="Passed">Passed</el-radio>
          <el-radio label="Failed">Failed</el-radio>
          <el-radio label="Unfinished">Unfinished</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="circles mb10 flex align-center space-around wrap">
      <circleProgress
        :amount="users"
        type="Users"
        icon="fas fa-users"
        :max="users"
      ></circleProgress>
      <circleProgress
        :amount="CompanyScore"
        type="Company Score"
        icon="fas fa-star"
        :max="100"
      ></circleProgress>
    </div>

    <!-- <div class="btns flex center mb10">
      <button
        :class="{ 'is-active': this.showSyso }"
        class="button"
        @click="() => (this.showSyso = true)"
      >
        SYSO Mode
      </button>
      <button
        :class="{ 'is-active': !this.showSyso }"
        class="button"
        @click="() => (this.showSyso = false)"
      >
        USER Mode
      </button>
    </div> -->
    <!-- <div v-if="showSyso" class="circles circles-syso flex center wrap">
      <circleProgress
        :amount="users"
        type="USERS"
        icon="fas fa-users"
        :max="100"
      ></circleProgress>
      <circleProgress
        :amount="CompanyScore"
        type="CompanyScore"
        icon="fa fa-user-secret"
        :max="100"
      ></circleProgress>
    </div> -->
    <!-- <div v-else class="circles circles-user flex center wrap">
      <circleProgress
        :amount="5"
        type="Scenarios"
        icon="fa fa-bars"
        :max="10"
      ></circleProgress>
      <circleProgress
        :amount="6"
        type="Virtual Cyber Room"
        icon="fas fa-shield-alt"
        :max="10"
      ></circleProgress>
    </div> -->

    <div class="category-statistics flex column align-center">
      <h3>Users Statistics</h3>
      <div class="category-circles flex center wrap">
        <circleProgress
          :amount="phishing"
          type="Phishing"
          icon="far fa-id-card"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="vishing"
          type="Vishing"
          icon="fas fa-microphone"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="smishing"
          type="Smishing"
          icon="far fa-comment-alt"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="publicNetwork"
          type="Public Network"
          icon="fas fa-wifi"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="sqlInjection"
          type="Sql Injection"
          icon="fas fa-syringe"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="securePasswords"
          type="Secure Passwords"
          icon="fas fa-lock"
          :max="100"
        ></circleProgress>
        <circleProgress
          :amount="authorizedSoftware"
          type="Authorized Software"
          icon="fas fa-shield-alt"
          :max="100"
        ></circleProgress>
      </div>
    </div>
    <table class="unstriped mb10">
      <thead>
        <tr>
          <th style="color: #00f5af" width="25%">Department</th>
          <th style="color: #00f5af" width="25%">Passed</th>
          <th style="color: #00f5af" width="25%">In Progress</th>
          <th style="color: #00f5af" width="25%">Overdue</th>
          <th style="color: #00f5af" width="25%">Failed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <i
              class="fa fa-balance-scale"
              style="color: grey; font-size: 30px; line-height: 30px"
              aria-hidden="true"
            ></i>
          </td>
          <td style="color: #00f5af">85%</td>
          <td style="color: #00f5af">8</td>
          <td style="color: red">3</td>
          <td style="color: red">0</td>
        </tr>

        <tr>
          <td>
            <i
              class="fas fa-user-chart"
              style="color: grey; font-size: 30px; line-height: 30px"
            ></i>
          </td>
          <td style="color: #00f5af">84%</td>
          <td style="color: #00f5af">18</td>
          <td style="color: red">2</td>
          <td style="color: red">2</td>
        </tr>

        <tr>
          <td>
            <i
              style="color: grey; font-size: 30px; line-height: 30px"
              class="fas fa-headset"
            ></i>
          </td>
          <td style="color: #00f5af">73%</td>
          <td style="color: #00f5af">15</td>
          <td style="color: red">8</td>
          <td style="color: red">7</td>
        </tr>

        <tr>
          <td>
            <i
              style="color: grey; font-size: 30px; line-height: 30px"
              class="fas fa-file-invoice-dollar"
            ></i>
          </td>
          <td style="color: #00f5af">69%</td>
          <td style="color: #00f5af">30</td>
          <td style="color: red">5</td>
          <td style="color: red">3</td>
        </tr>

        <tr>
          <td>
            <i
              style="color: grey; font-size: 30px; line-height: 30px"
              class="fas fa-credit-card"
            ></i>
          </td>
          <td style="color: #00f5af">88%</td>
          <td style="color: #00f5af">0</td>
          <td style="color: red">1</td>
          <td style="color: red">1</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import circleProgress from "@/cmp/circle-progress";
export default {
  name: "dashboard",
  components: {
    circleProgress,
  },
  data() {
    return {
      CompanyScore: 82,
      users: 103,
      phishing: 85,
      vishing: 72,
      smishing: 64,
      publicNetwork: 86,
      sqlInjection: 69,
      securePasswords: 75,
      authorizedSoftware: 91,
      showSyso: false,
      showExportData: false,
      radio: false,
      options: [
        {
          value: "Today",
          label: "Today",
        },
        {
          value: "Yesterday",
          label: "Yesterday",
        },
        {
          value: "7 days ago",
          label: "7 days ago",
        },
        {
          value: "30 days ago",
          label: "30 days ago",
        },
      ],
      value: "Today",
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  mounted() {
    document.querySelector(".el-input__inner").style.backgroundColor =
      "transparent";
    document.querySelector(".el-input__inner").style.border =
      "1px solid rgba(0, 245, 175)";
    document.querySelector(".el-input__inner").style.color = "white";

    document.querySelector(
      ".el-scrollbar__view.el-select-dropdown__list"
    ).style.backgroundColor = "transparent";
    document.querySelector(
      ".el-scrollbar__view.el-select-dropdown__list"
    ).style.border = "1px solid rgba(0, 245, 175)";

    console.log(document.querySelectorAll(".el-radio__label"));
    document.querySelectorAll(".el-radio__label").forEach((radioLabel) => {
      console.log(radioLabel);
      radioLabel.classList.add("radio-label");
    });
    this.$parent.changeLogo();
  },
  created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
    } else {
      this.$store.dispatch({ type: "loadUsers" });
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
//Modify the color of the single selection
/deep/ {
  .el-radio {
    color: white;
    &:hover {
      color: $primary_color !important;
    }
    .el-radio__input {
      margin-bottom: px(5);
      &.is-checked {
        .el-radio__inner {
          background-color: $primary_color;
        }
        + .el-radio__label {
          color: $primary_color !important;
        }
      }
    }
  }
}
.el-select {
  max-width: 150px;
}
.demo-text {
  border: 1px solid red;
  padding: 10px;
}
.options-top {
  width: 100%;
  padding: 5px;
  margin-bottom: 16px;
  & .left {
    margin-left: 30px;
    .icon {
      position: relative;
      i {
        font-size: 30px;
        margin: 0 10px 10px;
        color: rgba(0, 245, 175, 0.85);
      }
      p {
        color: rgba(0, 245, 175, 0.85);
        position: absolute;
        bottom: -55px;
        text-align: center;
      }
    }
  }
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
  text-align: center;
}
tbody tr,
thead {
  border-bottom: 1px solid $primary_color;
  margin-bottom: 5px;
}
.circles {
  width: 100%;
  & > * {
    margin: 5px;
  }
}

.category-circles {
  & > * {
    margin: 5px;
  }
}

.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: white;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
</style>
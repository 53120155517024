<template>
  <section class="leaderboard flex column center">
    <h2 class="main-title">Leaderboard</h2>
    <div v-if="isLoading" class="loading flex center">
      <img
        class="loading-svg text-center"
        src="@/assets/loading.svg"
      />
    </div>
    <div v-if="!isLoading" class="leaderboard leaderboard-desktop flex column center">
      <section class="top flex align-center space-between">
        <h2 class="text-center">Leaderboard</h2>
        <h2>Top 5</h2>
      </section>
      <section class="top5 flex">
        <ul
          class="first-contact flex column mrg0 pad0"
          v-if="firstUser && firstUser.length"
        >
          <li class="mrg16" v-for="(user, idx) in firstUser" :key="user.id">
            <div
              class="avatar-img big mr10 mb10"
              v-if="user.img"
              :style="{
                backgroundImage:
                  'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }"
            />
            <!-- <img
              class="avatar-img big mr10 mb10"
              v-if="user.img"
              :src="require(`@/assets/img/avatars/${user.img}`)"
              alt=""
              srcset=""
            /> -->
            <img
              class="avatar-img big mr10 mb10"
              v-else
              src="@/assets/img/avatars/shutterstock_1575446116_1.png"
              alt=""
              srcset=""
            />
            <h3>
              {{ idx + 1 }}. {{ user.fullName }} -
              {{ user.totalScore.toFixed(0) }} score
            </h3>
          </li>
        </ul>
        <ul
          class="flex column four-contact"
          v-if="fourUsers && fourUsers.length"
        >
          <li
            class="flex center mb10 wrap"
            v-for="(user, idx) in fourUsers"
            :key="user.id"
          >
            <div
              class="avatar-img mr10"
              v-if="user.img"
              :style="{
                backgroundImage:
                  'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }"
            />
            <!-- <img
              class="avatar-img mr10"
              v-if="user.img"
              :src="require(`@/assets/img/avatars/${user.img}`)"
              alt=""
              srcset=""
            /> -->
            <img
              class="avatar-img mr10"
              v-else
              src="@/assets/img/avatars/shutterstock_1575446116_1.png"
              alt=""
              srcset=""
            />
            <h3 class="mrg0" style="width: 275px">
              {{ idx + 2 }}. {{ user.fullName }} -
              {{ user.totalScore.toFixed(0) }} score
            </h3>
          </li>
        </ul>
      </section>

      <section class="top flex align-center space-between">
        <h2 class="text-center">Leaderboard</h2>
        <h2>Top 20</h2>
      </section>
      <ul
        class="top20 flex center wrap"
        v-if="twentyUsers && twentyUsers.length"
      >
        <li
          class="flex column center mb10"
          v-for="(user, idx) in twentyUsers"
          :key="user.id"
        >
          <div
            class="avatar-img mr10"
            v-if="user.img"
            :style="{
              backgroundImage:
                'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }"
          />
          <!-- <img
            class="avatar-img mr10"
            v-if="user.img"
            :src="require(`@/assets/img/avatars/${user.img}`)"
            alt=""
            srcset=""
          /> -->
          <img
            class="avatar-img mr10"
            v-else
            src="@/assets/img/avatars/shutterstock_1575446116_1.png"
            alt=""
            srcset=""
          />
          <h3>
            {{ idx + 6 }}. {{ user.fullName }} -
            {{ user.totalScore.toFixed(0) }} score
          </h3>
        </li>
      </ul>
    </div>
    <div v-if="!isLoading" class="leaderboard leaderboard-mobile flex column center">
      <ul class="flex column four-contact" v-if="users && users.length">
        <li
          class="flex center mb10"
          v-for="(user, idx) in users"
          :key="user.id"
        >
          <div
            class="avatar-img mr10"
            v-if="user.img"
            :style="{
              backgroundImage:
                'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }"
          />
          <!-- <img
            class="avatar-img mr10"
            v-if="user.img"
            :src="'@/assets/img/avatars/' + user.img"
            alt=""
            srcset=""
          /> -->
          <img
            class="avatar-img mr10"
            v-else
            src="@/assets/img/avatars/shutterstock_1575446116_1.png"
            alt=""
            srcset=""
          />
          <h3 class="mrg0">
            {{ idx + 1 }}. {{ user.fullName }} -
            {{ user.totalScore.toFixed(0) }} score
          </h3>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
// import socketService from "@/service/socket-service.js";
import { API } from "aws-amplify";
import { onCreateUser } from "../graphql/subscriptions";
import { onUpdateUser } from "../graphql/subscriptions";
import { onDeleteUser } from "../graphql/subscriptions";

export default {
  name: "leaderboard",
  data() {
    return {
      users: [],
      firstUser: [],
      fourUsers: [],
      twentyUsers: [],
      interval: null,
      isLoading: true
    };
  },
  methods: {
    async getUsers(users = null) {
      this.isLoading = true
      if (users) var usersToDisplay = JSON.parse(JSON.stringify(users));
      else {
        var usersStore = this.$store.getters.users;
        usersToDisplay = JSON.parse(JSON.stringify(usersStore));
      }
      console.log(usersToDisplay);
      usersToDisplay = usersToDisplay.filter((user) => {
        return (
          user.totalScore &&
          (user.company === this.loggedinUser.company ||
            user.company === "for-demo")
        );
      });
      await usersToDisplay.sort(function (a, b) {
        return b.totalScore - a.totalScore;
      });
      this.users = [];
      this.firstUser = [];
      this.fourUsers = [];
      this.twentyUsers = [];
      this.users = JSON.parse(JSON.stringify(usersToDisplay));
      this.firstUser.push(usersToDisplay.shift());
      this.fourUsers = usersToDisplay.splice(0, 4);
      this.twentyUsers = usersToDisplay.splice(0, 15);
      this.isLoading = false
    },
    async subscribe() {
      await API.graphql({ query: onCreateUser }).subscribe({
        next: (eventData) => {
          console.log("create", eventData);
          let user = eventData.value.data.onCreateUser;
          var users = this.$store.getters.users;
          if (users.some((item) => item === user)) return;
          users = [...users, user];
          this.getUsers(users);
        },
      });
      await API.graphql({ query: onUpdateUser }).subscribe({
        next: (eventData) => {
          console.log("update", eventData);
          let user = eventData.value.data.onUpdateUser;
          var users = this.$store.getters.users;
          users.forEach((item, index) => {
            if (item.id === user.id)
              users[index] = JSON.parse(JSON.stringify(user));
          });
          this.getUsers(users);
        },
      });
      await API.graphql({ query: onDeleteUser }).subscribe({
        next: (eventData) => {
          console.log("delete", eventData);
          let user = eventData.value.data.onDeleteUser;
          var users = JSON.parse(JSON.stringify(this.users));
          users.forEach((item, index) => {
            if (item.id === user.id) delete users[index];
          });
          this.getUsers(users);
        },
      });
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    scenarioUsers() {
      var users = this.users.filter((user) => {
        return user.scores && user.scores.scenario;
      });
      return users;
    },
    cyberRoomUsers() {
      var users = this.users.filter((user) => {
        return user.scores && user.scores["cyber-room"];
      });
      return users;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    this.$parent.changeLogo();
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
    } else {
      await this.$store.dispatch({ type: "loadUsers" });
      this.getUsers();
      this.subscribe();
    }
  },
};
</script>

<style lang="scss" scoped>
.leaderboard {
  width: 100%;
}
ul {
  list-style-type: none;
  align-items: start;
  margin-left: 0;
}
.avatar-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 2pt solid #07eda8;
  border-radius: 12pt;
  box-shadow: 0 0 5px #07eda8;
  &.big {
    width: 150px;
    height: 150px;
  }
}
.top {
  width: 80%;
  border-bottom: 2px solid;
  & > * {
    margin: 0;
  }
}
.top20 {
  & > li {
    margin: 10px;
  }
}
.top5 {
  width: 80%;
  & > :first-child {
    width: 40%;
  }
}
</style>
<template>
  <div class="sql-injection flex column center">
    <!-- <game v-if="!readyIframe"></game> -->
    <quiz :quizNew="quizSqlInjection" v-if="!readyIframe"></quiz>
    <!-- <h3 v-if="!readyIframe" class="hint">Cyber Awareness Words</h3>
    <hangman v-if="!readyIframe"></hangman> -->

    <!-- <button
      class="btn-show-iframe button"
      v-if="done && !readyIframe"
      @click="showIframe"
    >
      Go To Sql-injection
    </button> -->
    <iframe
      v-show="readyIframe"
      ref="iframe1"
      @load="sendId"
      src="https://staging.new-ledge.com/scenarios/sql-injection/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button
      class="button to-profile-btn"
      @click="() => this.$router.push('/profile')"
    >
      Go back to Profile
    </button>
  </div>
</template>

<script>
import quiz from "@/cmp/quiz";
import { utilService } from "@/service/utils/util.service.js";

export default {
  components: {
    quiz,
  },
  name: "sql-injection",
  data() {
    return {
      id: null,
      done: null,
      readyIframe: null,
      quizSqlInjection: {
        questions: [
          {
            text: "Following the appropriate approvals, methods and security protocols, can you share development plans and code with the customer?",
            responses: [
              { text: "No" },
              {
                text: "Yes",
                correct: true,
              },
            ],
            message:
              "Only following your BU procedures and Information Security policies as well as the customer instructions on how to provide the code or documents. ",
          },
          {
            text: "Can I upload Amdocs or Customer data to a public repository (such as open source repositories - GitHub, Pastebin, Reddit)?",
            responses: [
              { text: "No", correct: true },
              {
                text: "Yes",
              },
            ],
            message:
              "Exposure of such data can risk our assets and agreements with the customer. You must remove it ASAP and report to security and your manager immediately. The faster you remove it and report it, the faster the incident can be contained and damage minimized.",
          },
        ],
      },
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    showIframe() {
      this.readyIframe = true;
    },
    sendId() {
      this.$refs["iframe1"].contentWindow.postMessage(
        this.loggedinUser.id,
        "*"
      );
      setTimeout(() => {
        this.done = true;
      }, 15000);
    },
  },
  async destroyed() {
    console.log("scenario destoyed");
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id: this.loggedinUser.id,
    });
    var idx = user.scores.findIndex((game) => {
      console.log(game.gameId, this.id);
      return game.gameId === this.id;
    });
    if (idx != -1) {
      if (user.scores[idx].endDownload) user.scores[idx].done = true;
      else {
        user.scores[idx].endDownload = Date.now() + "";
        user.scores[idx].done = false;
        user.scores[idx].time = (
          (user.scores[idx].endDownload - user.scores[idx].startDownload) /
          100
        ).toFixed(0);
      }
      await this.$store.dispatch({ type: "updateUser", user });
    }
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
      return;
    }
    var user = JSON.parse(JSON.stringify(this.loggedinUser));
    if (!user.scores) user.scores = [];
    var game = {};
    game.gameId = utilService.makeId(10);
    this.id = game.gameId;
    game.type = this.$route.path.substring(1);
    game.startDownload = Date.now() + "";
    user.scores.push(game);
    await this.$store.dispatch({ type: "updateUser", user });
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.sql-injection {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 10px;
  //   left: 15px;
  //   padding: 10px;
  // }
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>
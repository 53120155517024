var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"profile flex column center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],ref:"modal",staticClass:"modal",style:({
      position: 'absolute',
      top: ("" + _vm.modaltop),
      left: ("" + _vm.modalleft),
    })},[_vm._v(" Just For Checking... ")]),(_vm.loggedinUser)?_c('div',{staticClass:"details-user flex column center"},[(_vm.loggedinUser.img)?_c('div',{staticClass:"avatar-img avatar mr10 mb10",style:({
        backgroundImage:
          'url(' + require(("@/assets/img/avatars/" + (_vm.loggedinUser.img))) + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      })},[_c('i',{staticClass:"far fa-edit change-icon hover-pointer",on:{"click":function($event){_vm.showAvatars = !_vm.showAvatars}}})]):_vm._e(),(_vm.showAvatars)?_c('div',{staticClass:"avatars flex center wrap"},_vm._l((14),function(index){return _c('div',{key:index,staticClass:"avatar-img mr10 mb10 hover-pointer",style:({
          backgroundImage:
            'url(' +
            require(("@/assets/img/avatars/shutterstock_1575446116_" + index + ".png")) +
            ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }),on:{"click":function (ev) { return _vm.changeAvatar(ev); }}})}),0):_vm._e(),_c('h3',{staticClass:"mr16"},[_vm._v("Hi "+_vm._s(_vm.loggedinUser.fullName.split("_")[0]))])]):_c('h3',[_vm._v("Hi guest, please login first")]),_vm._m(0),(!_vm.avg)?_c('h3',[_vm._v("Check Download Speed...")]):_vm._e(),(_vm.avg && _vm.avg < 40)?_c('h3',[_vm._v(" Your connection speed is lower then recommanded ")]):_vm._e(),_c('div',{staticClass:"cells flex center wrap text-center"},[_c('div',{staticClass:"scenario flex column center mrg5"},[_c('div',{staticClass:"cell cell-scenario",on:{"click":function($event){return _vm.toPage('/scenario')}}}),_c('div',{staticClass:"cell-text flex column center",on:{"click":function($event){return _vm.toPage('/scenario')}}},[_c('p',[_vm._v("Protect your data")]),_c('p',[_vm._v("play to keep "+_vm._s(_vm.loggedinUser.company)+" safe")])])]),_c('div',{staticClass:"cyber-room flex column center mrg5"},[_c('div',{staticClass:"cell cell-cyber-room",on:{"click":function($event){return _vm.toPage('/cyber-room')}}}),_c('div',{staticClass:"cell-text flex column center",on:{"click":function($event){return _vm.toPage('/cyber-room')}}},[_c('p',[_vm._v("It's the little things you do")]),_c('p',[_vm._v("enter the Virtual Escape Room")]),_c('p',[_vm._v("and solve the challenges")])])]),_c('div',{staticClass:"sql-injection flex column center mrg5"},[_c('div',{staticClass:"cell cell-sql-injection",on:{"click":function($event){return _vm.toPage('/sql-injection')}}}),_c('div',{staticClass:"cell-text flex column center",on:{"click":function($event){return _vm.toPage('/sql-injection')}}},[_c('p',[_vm._v("Work in a secure mode and")]),_c('p',[_vm._v("protect your code")]),_c('p',[_vm._v("(Application Security for R&D)")])])])]),_c('div',{staticClass:"hero-text text-center"},[_c('h2',[_vm._v(" You don't need to be a superhero, to keep "+_vm._s(_vm.loggedinUser.company)+" safe ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-title text-center"},[_c('p',[_vm._v("Are you ready for the challenge?")]),_c('p',[_vm._v("Complete the 3D games to get the highest score and win cool prizes!")])])}]

export { render, staticRenderFns }
<template>
  <div class="profile flex column center">
    <h1>Profile</h1>
    <div class="flex center mb10" v-for="user in users" :key="user.id">
      <h3 class="mrg0">{{ user.fullName }}</h3>
      <button @click="()=>toPage(`newUpdateUser/${user.AuthId}`)" class="button">update</button>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { listUsers } from "../graphql/queries";
import { onCreateUser } from "../graphql/subscriptions";
import { onUpdateUser } from "../graphql/subscriptions";
import { onDeleteUser } from "../graphql/subscriptions";

export default {
  name: "new-profile",
  data() {
    return {
      users: [],
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    toPage(path) {
      this.$router.push(`/${path}`)
    },
    async getUsers() {
      const users = await API.graphql({
        query: listUsers,
      });
      this.users = users.data.listUsers.items;
    },
    async subscribe() {
      await API.graphql({ query: onCreateUser }).subscribe({
        next: (eventData) => {
          console.log("create", eventData);
          let user = eventData.value.data.onCreateUser;
          if (this.users.some((item) => item === user)) return; // remove duplications
          this.users = [...this.users, user];
        },
      });
      await API.graphql({ query: onUpdateUser }).subscribe({
        next: (eventData) => {
          console.log("update", eventData);
          let user = eventData.value.data.onUpdateUser;
          var users = JSON.parse(JSON.stringify(this.users))
          users.forEach((item,index)=> {
            if(item.id === user.id) users[index] = JSON.parse(JSON.stringify(user))
          })
          this.users = JSON.parse(JSON.stringify(users));
          // if (this.users.some((item) => item.fullName === user.fullName)) return; // remove duplications
          // this.users = [...this.users, user];
        },
      });
      await API.graphql({ query: onDeleteUser }).subscribe({
        next: (eventData) => {
          console.log("delete", eventData);
          let user = eventData.value.data.onDeleteUser;
           var users = JSON.parse(JSON.stringify(this.users))
          users.forEach((item,index)=> {
            if(item.id === user.id) delete users[index] 
          })
          this.users = JSON.parse(JSON.stringify(users));
          // if (this.users.some((item) => item === user)) return; // remove duplications
          // this.users = [...this.users, user];
        },
      });
    },
  },
  async created() {
    this.getUsers();
    this.subscribe();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
</style>
import Vue from 'vue';
import VueRouter from 'vue-router';
import login from '../views/login';
import oldLogin from '../views/oldLogin';
// import addUser from '../views/addUser';
import controllUsers from '../views/controllUsers';
import userDetails from '../views/userDetails';
import profile from '../views/profile';
import newProfile from '../views/newProfile';
import leaderboard from '../views/leaderboard';
import scenario from '../views/scenario';
import dashboard from '../views/dashboard';
import speedTest from '../views/speedTest';
import cyberRoom from '../views/cyber-room';
import sqlInjection from '../views/sql-injection';
import newUpdateUser from '../views/newUpdateUser';

Vue.use(VueRouter)

const routes = [{
        path: '/sql-injection',
        name: 'sqlInjection',
        component: sqlInjection
    },
    {
        path: '/scenario',
        name: 'scenario',
        component: scenario
    },
    {
        path: '/newUpdateUser/:userId',
        name: 'newUpdateUser',
        component: newUpdateUser
    },
    {
        path: '/speedTest',
        name: 'speedTest',
        component: speedTest
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard
    },
    {
        path: '/cyber-room',
        name: 'cyberRoom',
        component: cyberRoom
    },
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: leaderboard
    },
    {
        path: '/newProfile',
        name: 'newProfile',
        component: newProfile
    },
    {
        path: '/profile',
        name: 'profile',
        component: profile
    },
    // {
    //     path: '/addUser',
    //     name: 'addUser',
    //     component: addUser
    // },
    {
        path: '/controllUsers',
        name: 'controllUsers',
        component: controllUsers
    },
    {
        path: '/userDetails/:userId',
        name: 'userDetails',
        component: userDetails
    },
    {
        path: '/oldLogin',
        name: 'oldLogin',
        component: oldLogin
    },
    {
        path: '/',
        name: 'login',
        component: login
    }
]

const router = new VueRouter({
    routes
})

export default router
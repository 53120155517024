import httpService from './http-service.js'
import { Auth } from 'aws-amplify';

export const userService = {
    login,
    logout,
    signup,
    getUsers,
    getById,
    remove,
    update,
    add,
    updatePassword
}

function getUsers() {
    return httpService.get('user')
}

function getById(userId) {
    return httpService.get(`user/${userId}`)
}

function remove(userId) {
    return httpService.delete(`user/${userId}`)
}

function update(user) {
    return httpService.put(`user/${user._id}`, user)
}

async function login(authId) {
    // const user = await httpService.post('auth/login', userCred)
    try {
        const user = await httpService.get(`user?authId=${authId}`)
        return _handleLogin(user)
    } catch (error) {
        console.log('error signing in', error);
        return { error }
    }
    // try {
    //     const user = await Auth.signIn(userCred.fullName, userCred.password);
    //     return _handleLogin(user)
    // } catch (error) {
    //     console.log('error signing in', error);
    //     return { error }
    // }
}

async function signup(userCred) {
    const user = await httpService.post('auth/signup', userCred)
    return _handleLogin(user)
}
async function updatePassword(userCred) {
    return await httpService.post('auth/updatePassword', userCred)
}
async function add(userCred) {
    return await httpService.post('auth/addUser', userCred)
}
async function logout() {
    // await httpService.post('auth/logout');
    try {
        await Auth.signOut();
        sessionStorage.clear();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}



function _handleLogin(user) {
    sessionStorage.setItem('user', JSON.stringify(user))
    return user;
}
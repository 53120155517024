<template>
  <section v-if="user" class="user-details flex column center">
    <h2 class="text-center">User Profile</h2>
    <div v-if="isLoading" class="loading flex center">
      <img
        class="loading-svg text-center"
        src="@/assets/loading.svg"
      />
    </div>
    <div v-else class="flex column center">
    <div class="btns flex center wrap">
      <button
        class="button"
        :class="{ 'is-active': this.showChangePassword }"
        @click="() => (this.showChangePassword = true)"
      >
        Change password
      </button>
      <button
        class="button"
        :class="{ 'is-active': !this.showChangePassword }"
        @click="() => (this.showChangePassword = false)"
      >
        Edit user details
      </button>
    </div>
    <div v-if="!showChangePassword" class="details">
      <form @submit.prevent="updateUser" class="flex column center">
        <el-input
          v-model="user.fullName"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Username'"
          placeholder="Username"
          clearable
          suffix-icon="el-icon-edit"
        ></el-input>
        <!-- <el-input
          v-model="user.email"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Email'"
          placeholder="Email"
          clearable
          suffix-icon="far fa-envelope"
        ></el-input> -->
        <el-input
          v-model="user.company"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Company'"
          placeholder="Company"
          clearable
          suffix-icon="far fa-building"
        ></el-input>
        <!-- <el-date-picker
          v-if="loggedinUser.isAdmin"
          v-model="dates"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker> -->
        <el-checkbox v-if="loggedinUser.isAdmin" v-model="user.isAdmin"
          >Is Admin?</el-checkbox
        >
        <section class="upload-img flex column center">
          <p>Upload company logo:</p>
          <template v-if="!isLoading">
            <label for="imgUploader">
              <i class="fas fa-cloud-upload-alt hover-pointer"></i>
            </label>
            <input
              type="file"
              name="img-uploader"
              id="imgUploader"
              @change="onUploadImg"
            />
          </template>
          <img
            class="loader"
            v-else
            src="@/assets/loading.svg"
            alt=""
          />
          <div v-if="compImg" class="img-list">
            <section class="imgs flex center">
              <img class="border-radius mrg5" :src="compImg" alt="img..." />
            </section>
          </div>
        </section>
        <button type="button" class="button" @click.stop="updateUser">
          Update User
        </button>
      </form>
    </div>
    <div
      v-if="showChangePassword && !isLoadingVerification"
      class="changePssword"
    >
      <form class="flex column center">
        <el-input
          v-if="showVerification"
          v-model="newPassword"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'New password'"
          placeholder="New password"
          clearable
          suffix-icon="fas fa-key"
        ></el-input>
        <el-input
          v-if="showVerification"
          v-model="replayNewPassword"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Confirm new password'"
          placeholder="Confirm new password"
          clearable
          suffix-icon="fas fa-key"
        ></el-input>
        <el-input
          v-if="showVerification"
          v-model="verificationCode"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'verification Code'"
          placeholder="verificationCode"
          clearable
          suffix-icon="fas fa-key"
        ></el-input>
        <p class="error-msg text-center" v-if="err">{{ err }}</p>
        <button
          v-if="showVerification"
          type="button"
          class="button"
          @click.stop="confirmVerification"
        >
          Change password
        </button>
        <button
          v-if="!showVerification"
          type="button"
          class="button"
          @click.stop="sendVerificationCode"
        >
          Send verification code
        </button>
      </form>
    </div>
    <div v-if="showChangePassword && isLoadingVerification">
      <img src="@/assets/loading.svg" alt="" srcset="" />
    </div>
    </div>
  </section>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "user-details",
  data() {
    return {
      showVerification: false,
      verificationCode: null,
      user: null,
      showChangePassword: false,
      isLoading: true,
      isLoadingImg: false,
      compImg: null,
      newPassword: null,
      replayNewPassword: null,
      err: null,
      isLoadingVerification: false,
      // dates: null,
    };
  },
  methods: {
    async sendVerificationCode() {
      this.isLoadingVerification = true;
      try {
        const res = await Auth.forgotPassword(this.loggedinUserAuth.username);
        console.log(res);
        this.showVerification = true;
        this.isLoadingVerification = false;
      } catch (err) {
        this.isLoadingVerification = false;
        console.log(err);
        this.$message({
          showClose: true,
          message: err.message,
          type: "warning",
          duration: 5000,
        });
      }
    },
    async confirmVerification() {
      console.log("confirmVerification");
      if (this.newPassword != this.replayNewPassword || !this.newPassword) {
        this.$message({
          showClose: true,
          message: "passwords not equals",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      this.isLoadingVerification = true;
      try {
        await Auth.forgotPasswordSubmit(
          this.loggedinUserAuth.username,
          this.verificationCode,
          this.newPassword
        );
        this.$message({
          showClose: true,
          message: `password changed succesfully!`,
          type: "success",
          duration: 5000,
        });
        this.isLoadingVerification = false;
        this.$router.back();
      } catch (err) {
        console.log(err);
        this.isLoadingVerification = false;
        this.$message({
          showClose: true,
          message: err.message,
          type: "warning",
          duration: 5000,
        });
      }
    },
    async updateUser() {
      this.user.compImg = this.compImg;
      // this.user.startDate = new Date(this.dates[0]).getTime();
      // this.user.endDate = new Date(this.dates[1]).getTime();
      var res = await this.$store.dispatch({
        type: "updateUser",
        user: this.user,
      });
      if (!res.err) {
        console.log(res);
        await this.$message({
          showClose: true,
          message: `${res.newUser.fullName} update sucessfully!`,
          type: "success",
          duration: 1500,
        });
        this.$parent.changeLogo();
      } else {
        this.$message({
          showClose: true,
          message: `Something was wrong, please try again.`,
          type: "warning",
          duration: 1500,
        });
        return;
      }
    },
    async onUploadImg(ev) {
      this.isLoadingImg = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.compImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoadingImg = false;
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    loggedinUserAuth() {
      return this.$store.getters.loggedinUserAuth;
    },
  },
  async created() {
    this.isLoading = true
    const _id = this.$route.params.userId;
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id,
    });
    this.user = JSON.parse(JSON.stringify(user));
    this.compImg = user.compImg;
    this.$parent.changeLogo();
    this.isLoading = false
    // this.dates = [user.startDate, user.endDate];
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
/deep/ {
  .el-range-editor.el-input__inner {
    border: none;
    margin-left: 57px;
    &.el-date-editor .el-range__icon {
      color: #878787;
      position: absolute;
      right: 28px;
      font-size: 19px;
    }
    & .el-range-input {
      font-family: regular;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid white;
      margin-bottom: 0.4375rem;
      border-radius: 0px;
      color: white;
      &::placeholder {
        color: white;
      }
    }
  }
}
.img-list img {
  width: 100px;
}
.upload-img {
  & i {
    font-size: 40px;
    color: #00f5af;
  }
}

#imgUploader {
  display: none;
}

form {
  max-width: 250px;
}
.btns {
  margin-bottom: 20px;
}
.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: black;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}

.error-msg {
  margin: 8px;
  margin-bottom: 17px;
  color: red;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"leaderboard flex column center"},[_c('h2',{staticClass:"main-title"},[_vm._v("Leaderboard")]),(_vm.isLoading)?_c('div',{staticClass:"loading flex center"},[_c('img',{staticClass:"loading-svg text-center",attrs:{"src":require("@/assets/loading.svg")}})]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"leaderboard leaderboard-desktop flex column center"},[_vm._m(0),_c('section',{staticClass:"top5 flex"},[(_vm.firstUser && _vm.firstUser.length)?_c('ul',{staticClass:"first-contact flex column mrg0 pad0"},_vm._l((_vm.firstUser),function(user,idx){return _c('li',{key:user.id,staticClass:"mrg16"},[(user.img)?_c('div',{staticClass:"avatar-img big mr10 mb10",style:({
              backgroundImage:
                'url(' + require(("@/assets/img/avatars/" + (user.img))) + ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            })}):_c('img',{staticClass:"avatar-img big mr10 mb10",attrs:{"src":require("@/assets/img/avatars/shutterstock_1575446116_1.png"),"alt":"","srcset":""}}),_c('h3',[_vm._v(" "+_vm._s(idx + 1)+". "+_vm._s(user.fullName)+" - "+_vm._s(user.totalScore.toFixed(0))+" score ")])])}),0):_vm._e(),(_vm.fourUsers && _vm.fourUsers.length)?_c('ul',{staticClass:"flex column four-contact"},_vm._l((_vm.fourUsers),function(user,idx){return _c('li',{key:user.id,staticClass:"flex center mb10 wrap"},[(user.img)?_c('div',{staticClass:"avatar-img mr10",style:({
              backgroundImage:
                'url(' + require(("@/assets/img/avatars/" + (user.img))) + ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            })}):_c('img',{staticClass:"avatar-img mr10",attrs:{"src":require("@/assets/img/avatars/shutterstock_1575446116_1.png"),"alt":"","srcset":""}}),_c('h3',{staticClass:"mrg0",staticStyle:{"width":"275px"}},[_vm._v(" "+_vm._s(idx + 2)+". "+_vm._s(user.fullName)+" - "+_vm._s(user.totalScore.toFixed(0))+" score ")])])}),0):_vm._e()]),_vm._m(1),(_vm.twentyUsers && _vm.twentyUsers.length)?_c('ul',{staticClass:"top20 flex center wrap"},_vm._l((_vm.twentyUsers),function(user,idx){return _c('li',{key:user.id,staticClass:"flex column center mb10"},[(user.img)?_c('div',{staticClass:"avatar-img mr10",style:({
            backgroundImage:
              'url(' + require(("@/assets/img/avatars/" + (user.img))) + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          })}):_c('img',{staticClass:"avatar-img mr10",attrs:{"src":require("@/assets/img/avatars/shutterstock_1575446116_1.png"),"alt":"","srcset":""}}),_c('h3',[_vm._v(" "+_vm._s(idx + 6)+". "+_vm._s(user.fullName)+" - "+_vm._s(user.totalScore.toFixed(0))+" score ")])])}),0):_vm._e()]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"leaderboard leaderboard-mobile flex column center"},[(_vm.users && _vm.users.length)?_c('ul',{staticClass:"flex column four-contact"},_vm._l((_vm.users),function(user,idx){return _c('li',{key:user.id,staticClass:"flex center mb10"},[(user.img)?_c('div',{staticClass:"avatar-img mr10",style:({
            backgroundImage:
              'url(' + require(("@/assets/img/avatars/" + (user.img))) + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          })}):_c('img',{staticClass:"avatar-img mr10",attrs:{"src":require("@/assets/img/avatars/shutterstock_1575446116_1.png"),"alt":"","srcset":""}}),_c('h3',{staticClass:"mrg0"},[_vm._v(" "+_vm._s(idx + 1)+". "+_vm._s(user.fullName)+" - "+_vm._s(user.totalScore.toFixed(0))+" score ")])])}),0):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"top flex align-center space-between"},[_c('h2',{staticClass:"text-center"},[_vm._v("Leaderboard")]),_c('h2',[_vm._v("Top 5")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"top flex align-center space-between"},[_c('h2',{staticClass:"text-center"},[_vm._v("Leaderboard")]),_c('h2',[_vm._v("Top 20")])])}]

export { render, staticRenderFns }
<template>
  <div class="cyber-room flex column center">
    <quiz :quizNew="quizCyberRoom" v-if="!readyIframe"></quiz>
    <iframe
      ref="iframe2"
      @load="sendId"
      src="https://staging.new-ledge.com/scenarios/virtual-escape-room/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button
      class="button to-profile-btn"
      @click="() => this.$router.push('/profile')"
    >
      Go back to Profile
    </button>
  </div>
</template>

<script>
import quiz from "@/cmp/quiz";
import { utilService } from "@/service/utils/util.service.js";

export default {
  name: "cyber-room",
  data() {
    return {
      id: null,
      done: null,
      readyIframe: null,
      quizCyberRoom: {
        questions: [
          {
            text: "You wish to share tasks and documents with your team via Whatsapp. Is it allowed?",
            responses: [
              { text: "No", correct: true },
              {
                text: "Yes",
              },
            ],
            message:
              "Company data cannot not be shared via WhatsApp or any other private channels such as Gmail, Facebook, etc. Sharing company data can only be used via Amdocs official and secured work channels (Emails, Teams, etc.)",
          },
          {
            text: "Can I restrict access to my office documents and files?",
            responses: [
              { text: "No" },
              {
                text: "Yes",
                correct: true,
              },
            ],
            message:
              "You can do it using OneDrive and grant access to specific people you define.",
          },
        ],
      },
    };
  },
  components: {
    quiz,
    // game,
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    sendId() {
      this.$refs["iframe2"].contentWindow.postMessage(
        this.loggedinUser.id,
        "*"
      );
      setTimeout(() => {
        this.done = true;
      }, 15000);
    },
    showIframe() {
      this.readyIframe = true;
      this.$refs["iframe2"].style.opacity = 1;
      this.$refs["iframe2"].style.width = "100%";
      this.$refs["iframe2"].style.height = "100%";
    },
  },
  mounted() {
    this.$refs["iframe2"].style.opacity = 1;
    this.$refs["iframe2"].style.width = "1px";
    this.$refs["iframe2"].style.height = "1px";
  },
  async destroyed() {
    console.log("cyber-room destoyed");
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id: this.loggedinUser.id,
    });
    var idx = user.scores.findIndex((game) => {
      console.log(game.gameId, this.id);
      return game.gameId === this.id;
    });
    if (idx != -1) {
      if (user.scores[idx].endDownload) user.scores[idx].done = true;
      else {
        user.scores[idx].endDownload = Date.now() + "";
        user.scores[idx].done = false;
        user.scores[idx].time = (
          (user.scores[idx].endDownload - user.scores[idx].startDownload) /
          100
        ).toFixed(0);
      }
      await this.$store.dispatch({ type: "updateUser", user });
    }
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.push("/");
      return;
    }
    var user = JSON.parse(JSON.stringify(this.loggedinUser));
    if (!user.scores) user.scores = [];
    var game = {};
    game.gameId = utilService.makeId(10);
    this.id = game.gameId;
    game.type = this.$route.path.substring(1);
    game.startDownload = Date.now() + "";
    user.scores.push(game);
    await this.$store.dispatch({ type: "updateUser", user });
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.cyber-room {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 3px;
  //   left: 60px;
  //   @include for-wide-layout {
  //     top: -9px;
  //   }
  // }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>